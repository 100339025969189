<template>
  <router-view/>
</template>

<style lang="css">
  @import "./assets/bootstrap/css/bootstrap.min.css";
  @import url('https://fonts.cdnfonts.com/css/kollektif');

  html {
    position: relative;
    min-height: 100%;
  }
  
  body {
    margin-bottom: 100px; /* Margin bottom by footer height */
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px; /* Set the fixed height of the footer here */
  }
</style>
