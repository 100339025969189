<template>
    <div class="overlay mb-4">
        <div class="row justify-content-md-center mb-5">
            <div class="col-md-2 w-100">
                <img class="mx-auto d-block w-50" alt="Realtor Hop logo" src="../assets/img/RealtorHopLogo.svg">
            </div>
        </div>
    </div>
    <!-- Sign up or Login in -->
    <div class="container h-100">
        <div class="row justify-content-md-center mb-5">
            <div class="col-md-6">
                <h1 class="display-1 text-center brand-color brand-style">Realtor Hop</h1>
                <h2 class="display-6 text-center">Skip The Realtor, We Can Help.</h2>
                <br>
                <h2 class="display-6 text-center">Save An Average Of $27,000*</h2>
                <h2 class="display-6 text-center">When Selling Your Home.</h2>
            </div>
        </div>
        <div class="row justify-content-md-center mb-5">
            <div class="col-md-6">
                <p class="lead text-center">Realtor Hop is a new way to buy and sell homes. We connect buyers to
                    sellers directly. Using home automation, Realtor Hop will show buyers your home. Cut out the
                    middle man, we'll show you how.</p>
            </div>
        </div>
        <div class="row justify-content-md-center mb-5">
            <div class="col-md-6">
                <p class="display-3 text-center">Coming Early 2024</p>
            </div>
        </div>
    </div>
        
        <div class="footer mt-auto py-3">
        <hr>
        <p class="text-muted text-center">*Based on the average home value of $452,510 USD and the average combined buyer and seller realtor fee percentage of 6%.</p>
    </div>
</template>

<script>
export default {
    name: 'HomeView',
    props: {
        msg: String
    },
    data() {
        return {
            User: {
                email: '',
                password: '',
            },
        }
    },
    computed: {
        passwordConfirmed() {
            return this.User.passwordConfirm == this.User.password
        }
    },
    methods: {
        submit() {
            console.log(this.User)
        }
    }
}
</script>

<style scoped>
.overlay {
    background-image: url("../assets/img/banner_bw_bkgd.png");
    background-size: auto;
    background-color: transparent;
}
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');
 .brand-color {
  color: #ED155D;
 }

 .brand-style {
    font-family: 'Nunito', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 86px;
    font-weight: 800;
 }
</style>